import axios from 'axios'
import store from '@/store/index'

const config = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

const clearUserData = () => {
  store.commit('auth/updateToken', null)
  store.commit('auth/updateExpiry', null)
  store.commit('auth/updateAccountInfo', {})
  store.commit('auth/updatePermissions', [])
  store.commit('auth/updateRoleStatus', false)
  store.commit('auth/updateAbilities', [])
}

const responseHandler = response => {
  if (response.status === 401) {
    clearUserData()
    window.location = '/login'
  }
  return response
}
const errorHandler = error => {
  if (error.response.status === 401) {
    clearUserData()
    window.location = '/login'
  }
  return Promise.reject(error)
}
export default {
  execute(method, resource, isAuthReq, data) {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (isAuthReq) {
      headers.Authorization = store.state.auth.Token
    }
    config.interceptors.response.use(
      response => responseHandler(response),
      error => errorHandler(error),
    )
    return config({
      headers,
      method,
      url: resource,
      data,
    })
  },
  getData(apiEndpoint, isAuthReq) {
    return this.execute('get', apiEndpoint, isAuthReq)
  },
  postData(apiEndpoint, isAuthReq, data) {
    return this.execute('post', apiEndpoint, isAuthReq, data)
  },
  updateData(apiEndpoint, isAuthReq, data) {
    return this.execute('patch', apiEndpoint, isAuthReq, data)
  },
  deleteData(apiEndpoint, isAuthReq, data) {
    return this.execute('delete', apiEndpoint, isAuthReq, data)
  },
}
