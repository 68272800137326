export default {
  namespaced: true,
  state: {
    Token: null,
    Expiry: null,
    permissions: [],
    abilities: [],
    accountInfo: {},
    isRoleChosen: false,
    company: {},
  },
  mutations: {
    updateToken(state, data) {
      state.Token = data
    },
    updateExpiry(state, data) {
      state.Expiry = data
    },
    updatePermissions(state, data) {
      state.permissions = data
    },
    updateAccountInfo(state, data) {
      state.accountInfo = data
    },
    updateRoleStatus(state, data) {
      state.isRoleChosen = data
    },
    updateAbilities(state, data) {
      state.abilities = data
    },
    updateCompany(state, data) {
      state.company = data
    },
  },
  getters: {
    userLoggedIn(state) {
      return state.Token && state.Expiry
        ? new Date() < new Date(state.Expiry) : false
    },
    corporateLogo(state) {
      return state.accountInfo.b2e_account
        && state.accountInfo.b2e_account.company.logo
        ? state.accountInfo.b2e_account.company.logo : ''
    },
  },
}
