import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      debounce: null,
    }
  },
  methods: {
    isObjectEmpty(obj) {
      return Object.keys(obj).length
    },
    formatDate(dateString, format) {
      return moment(dateString).format(format || 'DD MMM YY, hh:mm A')
    },
    checkDate(startDate, endDate) {
      const diff = moment(endDate).diff(moment(startDate), 'days')
      if (diff < 0) {
        return moment(startDate).add(14, 'days').format('YYYY-MM-DD')
      }
      return endDate
    },
    formatClassName(status) {
      if (status === 'PENDING' || status === 'BOOKING_HOLD' || status === 'RESUBMIT' || status === 'HOLD' || status === 'PARTIAL') {
        return 'light-warning'
      }
      if (status === 'BOOKING_FAILED' || status === 'FAILED' || status === 'REJECTED' || status === 'TICKETING_FAILED' || status === 'CANCELLED') {
        return 'light-danger'
      }
      if (status === 'REFUNDED' || status === 'INCOMPLETE' || status === 'CONFIRMED') {
        return 'light-info'
      }
      if (status === 'BOOKING_SUCCESS' || status === 'TICKET_ISSUED' || status === 'TICKETING_SUCCESS' || status === 'SUCCESS' || status === 'APPROVED' || status === 'PAID') {
        return 'light-success'
      }
      return 'light-dark'
    },
    formatStatus(status) {
      const formattedStatusValues = {
        // flight
        BOOKING_CANCELLED: 'Booking Cancelled',
        TICKET_CANCELLED: 'Ticket Cancelled',
        BOOKING_HOLD: 'On Hold',
        BOOKING_FAILED: 'Booking Failed',
        TICKETING_FAILED: 'Ticketing Failed',
        TICKETING_SUCCESS: 'Ticket Issued',
        TICKET_ISSUED: 'Ticket Issued',
        PENDING: 'Pending',
        IN_PROCESS: 'Pending',
        // hotel
        CANCELLED: 'Cancelled',
        FAILED: 'Failed',
        // store
        PARTIAL: 'Partial',
        PAID: 'Paid',
      }
      if (formattedStatusValues[status]) {
        return formattedStatusValues[status]
      }
      // before changing; check return value dependency
      return 'Confirmed'
    },
    debounceSearch(offset, callback) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        callback()
      }, 500)
    },
    bookingID(product, data) {
      if (product === 'FLIGHT') {
        return data.flight_booking_reference.booking_id
      }
      if (product === 'HOTEL') {
        return data.booking_reference.go_zayaan_reference
      }
      if (product === 'BUS') {
        return data.bus_reference.booking_id
      }
      if (product === 'TOUR') {
        return data.tour_reference.booking_id
      }
      return 'N/A'
    },
    getCurrencyCode(region) {
      const data = {
        BD: 'BDT',
        PK: 'PKR',
        US: 'USD',
      }
      return data[region] ? data[region] : 'BDT'
    },
    notificationMessage(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
          text,
        },
      }, { position: 'bottom-right', timeout: 5000, pauseOnHover: true })
    },
    checkForPlural(num, forSingular, forPlural) {
      return num === 1 ? forSingular : forPlural
    },
  },
}
