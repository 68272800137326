import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthorized } from '@/libs/acl/authorization'
import { canNavigate } from '@/libs/acl/routeProtection'
import account from '@/mixins/account'
import Vuex from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        loginRequired: true,
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/profile/AccountSettings.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Account Setting',
        resource: 'account_setting',
        action: 'view',
        breadcrumb: [
          {
            text: 'Account Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/views/search/Search.vue'),
      meta: {
        loginRequired: true,
      },
    },
    {
      path: '/users/employees',
      name: 'employees',
      component: () => import('@/views/users/Employees.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_employees',
        action: 'view',
        pageTitle: 'Employees',
        breadcrumb: [
          {
            text: 'Employees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/invitations',
      name: 'invitations',
      component: () => import('@/views/users/InvitationList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_employees',
        action: 'view',
        pageTitle: 'Invitation List',
        breadcrumb: [
          {
            text: 'Invitation List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/list',
      name: 'users',
      component: () => import('@/views/users/UserList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_users',
        action: 'view',
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/departments',
      name: 'departments',
      component: () => import('@/views/users/DepartmentList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_departments',
        action: 'view',
        pageTitle: 'Departments',
        breadcrumb: [
          {
            text: 'Departments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/invoice',
      name: 'invoices',
      component: () => import('@/views/accounts/invoice/InvoiceList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_invoices',
        action: 'view',
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/wallet/deposit',
      name: 'deposit',
      component: () => import('@/views/accounts/wallet/WalletDeposit.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_wallet',
        action: 'view',
        pageTitle: 'Credit Deposit',
        breadcrumb: [
          {
            text: 'Deposit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/wallet/credit',
      name: 'credit',
      component: () => import('@/views/accounts/wallet/WalletRequest.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_wallet',
        action: 'view',
        pageTitle: 'Credit Request',
        breadcrumb: [
          {
            text: 'Credit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/flight',
      name: 'flight-bookings',
      component: () => import('@/views/bookings/flight/FlightBookingList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_bookings',
        action: 'view',
        pageTitle: 'Flight Bookings',
        breadcrumb: [
          {
            text: 'Flight Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/tour',
      name: 'tour-bookings',
      component: () => import('@/views/bookings/tour/TourBookingList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_bookings',
        action: 'view',
        pageTitle: 'Tour Bookings',
        breadcrumb: [
          {
            text: 'Tour Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/hotel',
      name: 'hotel-bookings',
      component: () => import('@/views/bookings/hotel/HotelBookingList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_bookings',
        action: 'view',
        pageTitle: 'Hotel Bookings',
        breadcrumb: [
          {
            text: 'Hotel Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/bus',
      name: 'bus-bookings',
      component: () => import('@/views/bookings/bus/BusBookingList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_bookings',
        action: 'view',
        pageTitle: 'Bus Bookings',
        breadcrumb: [
          {
            text: 'Bus Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/all-trips',
      name: 'all',
      component: () => import('@/views/bookings/flight/TripList.vue'),
      meta: {
        loginRequired: true,
        resource: 'biz_all_trips',
        action: 'view',
        pageTitle: 'All Trips',
        breadcrumb: [
          {
            text: 'All Trips',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: () => import('@/views/faqs/FAQS.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'FAQs',
        breadcrumb: [
          {
            text: 'Frequently Asked Questions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/sales',
      name: 'sales-report',
      component: () => import('@/views/reports/SalesReport.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Sales Report',
        breadcrumb: [
          {
            text: 'Sales Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/employee',
      name: 'employee-report',
      component: () => import('@/views/reports/SalesReport.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Employee Report',
        breadcrumb: [
          {
            text: 'Employee Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/department',
      name: 'department-report',
      component: () => import('@/views/reports/SalesReport.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Department Report',
        breadcrumb: [
          {
            text: 'Department Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sales/invoice/create',
      name: 'sales-invoice-form',
      component: () => import('@/views/sales-store/Form.vue'),
      meta: {
        loginRequired: true,
        resource: 'sales_invoice_create',
        action: 'view',
      },
    },
    {
      path: '/sales/invoice/list',
      name: 'sales-invoice-list',
      component: () => import('@/views/sales-store/InvoiceList.vue'),
      meta: {
        resource: 'sales_invoice_list',
        action: 'view',
        loginRequired: true,
        pageTitle: 'Sales',
        breadcrumb: [
          {
            text: 'Invoice List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sales/invoice/preview/:id',
      name: 'sales-invoice-preview',
      component: () => import('@/views/sales-store/InvoicePreview.vue'),
      meta: {
        loginRequired: true,
        resource: 'sales_invoice_list',
        action: 'view',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/accept-invitation',
      name: 'accept-invitation',
      component: () => import('@/views/auth/EmployeeSignup.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/employee-profile',
      name: 'profile',
      component: () => import('@/views/profile/EmployeeProfile.vue'),
      meta: {
        loginRequired: true,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (to.path === '/' && to.query.expiry && to.query.id) {
    return account.methods.verifyToken(to.query.id, Vuex, next)
  }
  if (!isAuthorized(to)) {
    return next({ name: 'login' })
  }
  if (!canNavigate(to)) {
    return next({ name: 'home' })
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
