import axios from 'axios'
import api from '@/libs/api'
import helper from './helper'
import ability from '@/libs/acl/ability'

export default {
  mixins: [helper],
  methods: {
    clearUserData() {
      this.$store.commit('auth/updateToken', null)
      this.$store.commit('auth/updateExpiry', null)
      this.$store.commit('auth/updateAccountInfo', {})
      this.$store.commit('auth/updateCompany', {})
      this.$store.commit('auth/updatePermissions', [])
      this.$store.commit('auth/updateRoleStatus', false)
      this.$store.commit('auth/updateAbilities', [])
    },
    isUserValid() {
      return api.getData('account_management/user_account/', true)
        .then(response => response.data.status === 200).catch(() => false)
    },
    userLoggedIn(store) {
      if (store) {
        return store.getters['auth/userLoggedIn']
      }
      return this.$store.getters['auth/userLoggedIn']
    },
    setUserData(response, allowRedirect, url, store) {
      const vuex = this.$store || store
      vuex.commit('auth/updateToken', `Token ${response.token}`)
      vuex.commit('auth/updateExpiry', response.expiry)
      vuex.commit('auth/updatePermissions', response.user.groups)
      vuex.commit('auth/updateAccountInfo', response.user.account_info)
      vuex.commit('auth/updateCompany', response.user.account_info && response.user.account_info.b2e_account ? response.user.account_info.b2e_account.company : {})
      if (!store) {
        this.getPermissionList()
        if (allowRedirect) {
          this.$router.push(`${url}`)
            .then(() => {
              this.notificationMessage('success', 'CoffeeIcon', 'Welcome to GoZayaan Corporate Admin', 'You have successfully logged in. Now you can start to explore!')
            })
        } else {
          this.$router.replace('/')
            .then(() => {
              this.notificationMessage('success', 'CoffeeIcon', 'Welcome to GoZayaan Corporate Admin', 'You have successfully logged in. Now you can start to explore!')
            })
        }
      }
    },
    verifyToken(token, store, next) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}auth/get_new_token/`,
        method: 'get',
        headers: {
          Authorization: token,
        },
      }).then(async response => {
        if (response.status === 200) {
          await this.setUserData(response.data, false, '', store)
          this.getPermissionList(store, true)
          next({ path: '/' })
        }
        return false
      }).catch(() => {
        window.location.href = process.env.VUE_APP_ADMIN_URL
      })
    },
    getPermissionList(store, manualAbilityImport) {
      const vuex = store || this.$store
      const casl = manualAbilityImport ? ability : this.$ability
      api.getData('/auth/group_permissions_list/', true).then(response => {
        if (response.data.status) {
          const abilities = []
          const modules = response.data.result
          modules.forEach(name => {
            const modifyType = []
            if (name.view) {
              modifyType.push('view')
            }
            if (name.create) {
              modifyType.push('create')
            }
            if (name.update) {
              modifyType.push('update')
            }
            if (name.delete) {
              modifyType.push('delete')
            }
            abilities.push({
              action: modifyType,
              subject: name.module,
              conditions: {
                download_btn: true,
              },
            })
          })
          vuex.commit('auth/updateAbilities', abilities)
          casl.update(abilities)
        }
      }).catch()
    },
  },
}
