import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false, encryptionSecret: 's3cr3tEncrypti0n' })

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key),
    },
  })],
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
  },
  strict: process.env.DEV,
})
